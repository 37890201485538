import React, { useEffect, useState } from 'react';
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Adsense } from '@components/Adsense/Adsense'
import { Box, Label, Input, Textarea, Button, Message, Spinner, Text} from 'theme-ui'
import { Link } from 'gatsby';



const PageTarjetaSantander = props => {
  
  const [adsenseLoaded, setAdsenseLoaded] = useState(false);
  const [contentLoaded, setContentLoaded] = useState(false);

  useEffect(() => {
    setAdsenseLoaded(true);
    console.log('cargando')
    const timer = setTimeout(() => {
      console.log('cargando2')
      setContentLoaded(true);
    }, 2000); // 3 seconds delay

    return () => clearTimeout(timer);
  }, []);

  if (!contentLoaded) {
    return (
      <div style={{ 
        textAlign: 'center', 
        padding: '50px', 
        fontWeight: '800', 
        color: 'gray', 
        fontFamily: 'helvetica', 
        backgroundColor: 'rgba(226, 226, 226, 0.5)', // Gray overlay
        height: '100vh', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center' 
      }}>
        <p>Cargando...</p>
      </div>
    );
  }


  return (
    <Layout {...props}>
      <Seo title='Prestamos-Santander' />
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2292291997787849"
     crossOrigin="anonymous"></script>
      <Divider />
     <Stack>
     </Stack>
      <Adsense />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='Sacá tu tarjeta de crédito VISA Platinum del Banco Santander💸💳'
          subheader='Descubre los beneficios exclusivos y requisitos para solicitarla sin importar si estás en Veraz'
        />
      </Stack>
    
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <Link to="/prestamos-bbva">
          <Button style={{margin: '5px', width: '350px'}} variant="white">Préstamo de $650.000 💰 </Button>
        </Link>
        <Link to="/credito-hipotecario">
          <Button style={{margin: '5px', width: '350px'}} variant="white">Sacá tu Crédito Hipotecario 💸🏡 </Button>
        </Link>
        <Link to="/prestamos-nacion">
          <Button style={{margin: '5px', width: '350px'}} variant="white">Préstamo de $150.000 🤑 </Button>
        </Link>
        <Link to="/prestamos-personales">
          <Button style={{margin: '5px', width: '350px'}} variant="white">💰 Solicitá tu préstamo acá 💰 </Button>
        </Link>
      </div>
      <Adsense />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          subheader='
          Si estás en busca de una tarjeta de crédito confiable y llena de ventajas en Argentina, el Banco Santander tiene la opción perfecta para ti. 
          Con la tarjeta VISA Platinum, podrás acceder a un límite de hasta $1,200,000 y lo mejor de todo es que ¡puedes obtenerla solo con tu DNI,
           incluso si te encuentras en Veraz! En esta nota, te explicamos paso a paso todo lo que necesitas saber para solicitarla y disfrutar de sus múltiples beneficios.
           <br><br>
           <b>Todo lo que debes saber sobre las tarjetas de crédito del Banco Santander en Argentina</b>
            <br><br>
            El Banco Santander en Argentina ofrece una amplia gama de tarjetas de crédito diseñadas para satisfacer tus necesidades financieras y 
            estilo de vida. Desde la tarjeta clásica hasta la más exclusiva VISA Platinum, cada opción es personalizable y se adapta perfectamente a tus requerimientos.
            <br><br>
            <b>Requisitos para solicitar tu tarjeta de crédito</b>
            <br><br>
Para obtener una tarjeta de crédito del Banco Santander, solo necesitas cumplir con algunos requisitos sencillos:
<br><br>
- Ser mayor de 18 años. <br>
- Contar con ingresos mínimos de $20,000. <br>
- Presentar tu DNI e impuesto a las ganancias. <br> <br>
El Banco Santander también te ofrece flexibilidad en las opciones de pago, permitiéndote elegir entre el pago total o el pago mínimo. Además, tendrás la ventaja de financiar tus compras en cuotas sin interés.
          '
        />
      </Stack>
      <Adsense />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          subheader='
          
          <b>Beneficios exclusivos de la tarjeta de crédito del Banco Santander</b>
          <br><br>
          Una vez que obtengas tu tarjeta VISA Platinum, podrás disfrutar de una serie de beneficios exclusivos que harán tus compras y experiencias financieras aún más gratificantes:
          <br><br>
         1 Programa de puntos: Cada vez que realices una compra acumularás puntos que podrás canjear por descuentos en futuras compras, millas de viajero frecuente, productos y servicios.<br>
         2 Descuentos y promociones exclusivas: Obtén beneficios especiales en diversos establecimientos y categorías, incluyendo restaurantes, moda y viajes, entre otros.<br>
         3 Seguro de compras: Tu tarjeta VISA Platinum te protege en caso de robo, extravío o daño de los bienes adquiridos.
         <br><br>
          Además, el Banco Santander se distingue por ofrecer un servicio de atención al cliente excepcional, con atención telefónica disponible las 24 horas del día, los 7 días de la semana.
          
          '
        />
      </Stack>
      <Adsense />
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <Link to="/credito-hipotecario" style={{ textDecoration:'none', }}>
          <Text style={{margin: '5px', width: '100%', height: '100%',color: 'omegaDark' , fontWeight:'600'}}><span style={{fontWeight:'700'}}>
            También puede interesarte: </span>¡Volvío el Crédito Hipotecario!: Una Oportunidad Histórica para Acceder a la Vivienda Propia en Argentina. 🏡</Text>
        </Link>
      </div>
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          subheader='
          <b>El Banco Santander: Una institución financiera confiable</b>
          <br><br>
          El Banco Santander es una de las instituciones financieras más destacadas de Argentina, con una vasta trayectoria en el mercado y una sólida presencia a nivel nacional e internacional. Fundado en 1968 como Banco Español del Río de la Plata, actualmente atiende a más de 5 millones de clientes en todo el país a través de una amplia red de sucursales y servicios digitales.
          <br><br>
          Esta entidad se caracteriza por brindar una extensa variedad de productos y soluciones financieras, adaptadas a las necesidades individuales de sus clientes. Desde servicios de banca personal y comercial hasta seguros y productos de inversión, el Banco Santander se destaca por ofrecer un trato personalizado y asesoramiento de alta calidad en todas sus operaciones.
          <br><br>
          <b>Una opción indiscutible para tu bienestar financiero</b>
          <br><br>
          Si estás en busca de una tarjeta de crédito que te brinde comodidad, seguridad y una amplia variedad de beneficios, el Banco Santander es la opción a considerar en Argentina. Con requisitos accesibles y un sinfín de ventajas exclusivas, esta entidad financiera se convierte en tu mejor aliado para hacer frente a tus gastos y mejorar tu experiencia financiera.
          <br><br>
          En nuestro blog financiero, te mantendremos al tanto de las últimas noticias que impactan en tu economía, así como de los temas que más te interesan, como préstamos, créditos, tasas de interés y todo lo relacionado con el mundo financiero en Argentina. ¡Síguenos para estar siempre informado y tomar decisiones inteligentes con tu dinero!
          
          '
        />
      </Stack>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <Link to="https://www.anses.blog/volvio-el-credito-hipotecario-con-una-oportunidad-historica-para-tener-tu-casa-propia/" style={{ textDecoration:'none', }}>
          <Text style={{margin: '5px', width: '100%', height: '100%',color: 'omegaDark' , fontWeight:'600'}}><span style={{fontWeight:'700'}}>
            También puede interesarte: </span>¡Volvío el Crédito Hipotecario!: Una Oportunidad Histórica para Acceder a la Vivienda Propia en Argentina. 🏡</Text>
        </Link>
      </div>
      <Adsense />

    </Layout>
  )
}

export default PageTarjetaSantander
